import { DowntimeDetailsComponent } from './downtime-details/downtime-details.component';
import { ScheduleTracker } from './../../Services/Object_Classes/Machine/Machine';
import { AddPOComponent } from './add-po/add-po.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { ShippingInformationComponent } from './shipping-information/shipping-information.component';
import { RawCheckOutInformationComponent } from './raw-check-out-information/raw-check-out-information.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from '../../Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from '../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { MatPaginator, PageEvent, Sort } from '@angular/material';
import { OrdersComponent } from '../raw/PO/orders/orders.component';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AddCustomerComponent } from './Customer/add-customer/add-customer.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';
import { DetailPOPageComponent } from './detail-po/detail-po-page.component';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { PackingList_PO_List, PackingList, PackingList_PO_Part_List } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { AddPackingComponent } from '../invoice/add-packing/add-packing.component';
import { PackingListDB_controller } from 'src/app/Services/DB_Controller/PackingListDB_controller';
import { GenerateOldLabelComponent } from '../invoice/generate-old-label/generate-old-label.component';
import { GenerateQRCodeDialogComponent } from '../invoice/generate-qrcode-dialog/generate-qrcode-dialog.component';
import { UpdatePackingComponent } from '../invoice/update-packing/update-packing.component';
import { v4 as uuidv4 } from 'uuid';
import { UploadExcelDialogComponent } from 'src/app/Shared/upload-excel-dialog/upload-excel-dialog.component';
import JsBarcode from 'jsbarcode';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { AngularFireStorage } from '@angular/fire/storage';
import { UpdatePartComponent } from '../parts/PopUpModal/update-part/update-part.component';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { currentId } from 'async_hooks';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { ViewPartComponent } from './view-part/view-part.component';

@Component({
  selector: 'app-purchaseOrder',
  templateUrl: './purchaseOrder.component.html',
  styleUrls: ['./purchaseOrder.component.css']
})
export class PurchaseOrderComponent implements OnInit {

  POno: string;
  partNo: string;
  POquantity: number;
  CustomerDB_controller: CustomerDB_controller = new CustomerDB_controller(this.db, this.firestore);
  partDB_controllers: PartDB_controller = new PartDB_controller(this.db,this.storage,this.firestore);
  email: string;
  minDate: Date;
  maxDate: moment.Moment;
  startDate: Date = new Date();
  endDate: Date = new Date();
  AccessList: String[] = [];

  selectedStartDate: Date = new Date();
  selectedEndDate: Date = new Date();
  selectedEndDate2: Date = new Date();

  matSelectPart: Part[] = [];
  POlist: PurchaseOrder[] = [];
  deleteList: PurchaseOrder[] = [];
  ClonePOList: PurchaseOrder[] = [];
  Reportinglist: PurchaseOrder[] = [];
  CloneReportingList: PurchaseOrder[] = [];
  CloneScheduleList: PurchaseOrder[] = [];
  IncompletePOList: PurchaseOrder[] = [];
  IncompletePOGroupedList: PurchaseOrder[] = [];
  CloneJOList: PurchaseOrder[] = [];

  //Pagination Monitoring
  monitoringLength = 0;
  monitoringPageSize = 10;
  monitoringPageSizeOptions: number[] = [10, 25, 50];
  monitoringPageIndex = 0;
  monitoringOffset = this.monitoringPageSize * this.monitoringPageIndex;
  searchMonitor: string;
  sortMonitoredu = {
    active: '',
    direction: ''
  };
  @ViewChild('topSOMonitoringPaginator', { read: MatPaginator, static: false }) topSOMonitoringPaginator: MatPaginator;
  @ViewChild('bottomSOMonitoringPaginator', { read: MatPaginator, static: false }) bottomSOMonitoringPaginator: MatPaginator;

  //Pagination
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  @ViewChild('topPaginator', { read: MatPaginator, static: false }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: false }) bottomPaginator: MatPaginator;

  //Pagination Customer
  lengthcus = 0;
  pageSizecus  = 10;
  pageSizeOptionscus : number[] = [10, 25, 50];
  pageIndexcus  = 0;
  offsetcus  = this.pageSizecus  * this.pageIndexcus ;
  searchcus : string;
  sorteducus  = {
    active: '',
    direction: ''
  };
  Customer: Customer[] = [];
  CloneCustomer: Customer[] = [];
  @ViewChild('topPaginatorcus', { read: MatPaginator, static: false }) topPaginatorcus : MatPaginator;
  @ViewChild('bottomPaginatorcus', { read: MatPaginator, static: false }) bottomPaginatorcus : MatPaginator;

  //Pagination Reporting
  reportingLength = 0;
  reportingPageSize = 10;
  reportingPageSizeOptions: number[] = [10, 25, 50];
  reportingPageIndex = 0;
  reportingOffset = this.reportingPageSize * this.reportingPageIndex;
  sortReportingedu = {
    active: '',
    direction: ''
  };
  @ViewChild('topReportingPaginator', { read: MatPaginator, static: true }) topReportingPaginator: MatPaginator;
  @ViewChild('bottomReportingPaginator', { read: MatPaginator, static: true }) bottomReportingPaginator: MatPaginator;

  //Pagination Scheduling
  schedulingLength = 0;
  schedulingPageSize = 10;
  schedulingPageSizeOptions: number[] = [10, 25, 50];
  schedulingPageIndex = 0;
  schedulingOffset = this.schedulingPageSize * this.schedulingPageIndex;
  sortSchedulingedu = {
    active: '',
    direction: ''
  };
  @ViewChild('topSchedulingPaginator', { read: MatPaginator, static: true }) topSchedulingPaginator: MatPaginator;
  @ViewChild('bottomSchedulingPaginator', { read: MatPaginator, static: true }) bottomSchedulingPaginator: MatPaginator;

  //Pagination Machine job
  lengthJob = 0;
  pageSizeJob  = 10;
  pageSizeOptionsJob : number[] = [10, 25, 50];
  pageIndexJob  = 0;
  offsetJob  = this.pageSizeJob  * this.pageIndexJob ;
  searchJob : string;
  sorteduJob  = {
    active: '',
    direction: ''
  };
  Job: ScheduleTracker[] = [];
  CloneJob: ScheduleTracker[] = [];
  @ViewChild('topPaginatorJob', { read: MatPaginator, static: false }) topPaginatorJob : MatPaginator;
  @ViewChild('bottomPaginatorJob', { read: MatPaginator, static: false }) bottomPaginatorJob : MatPaginator;

  PODB_controller: PODB_controller = new PODB_controller(this.db);
  machineDB_controller = new MachineDB_controller(this.db);
  part_controller: PartDB_controller = new PartDB_controller(this.db,this.storage,this.firestore);
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);

  machinelist = [];
  soList = [];
  role:string;
  acccessRight:any[]=[];
  Staff: Staff[] = [];
  poStatus: string = "";
  monitorStatus: string = "";

  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private angularFireAuth: AngularFireAuth,
    private excelHelper: ExcelHelperService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private userinfoSrv:UserInfoService,
    private toast: ToastrService,
    private dateService: DateFormatService
  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.userinfoSrv.currentRole.subscribe(role=>{
      this.role = role
      if(this.userinfoSrv.checkPermission(this.role, 'PO Management',this.acccessRight)){
        this.disableDelete = false;
      }
    });
    this.userinfoSrv.currentAccessRights.subscribe(access=>{
      this.acccessRight = access
      if(this.userinfoSrv.checkPermission(this.role, 'PO Management',this.acccessRight)){
        this.disableDelete = false;
      }
    });
    this.userinfoSrv.currentSubmodules.subscribe(role=>{
      this.AccessList = role;
    });
  }
  disableDelete = true;

  check(PO: PurchaseOrder){
    const result = PO.PO_Part_List.find(p=>p.Added_TO_Packing===true)
    if(result ){
      return true;
    }
    else{
      return false;
    }
  }

  ngOnInit() {
    this.setup();
    this.initcus();
    this.initJob();
  }

  refresh(){
    this.setup();
    this.initcus();
  }
  
  setup(){
    this.spinner.show();
    this.machinelist = [];
    this.IncompletePOList = [];

    const db = this.db.database.ref('Machine/');
    db.once('value', (snapshot) => {
      snapshot.forEach(v => {
          this.machinelist.push(v.key);
      });
    });

    this.PODB_controller.getPOList().then(data => {
      this.POlist = data;
      //this.POlist = this.POlist.filter(p=>p.PO_Part_List.length>=1);
      this.length = this.POlist.length;
      this.ClonePOList = this.POlist.slice();
      this.limitList();
      this.spinner.hide();
    });

    this.PODB_controller.search_PO_withStatusNotCompleted().then(data => {
      this.IncompletePOList = data;

      this.IncompletePOList.sort((a,b)=>{
        return a.Longest_Date.getTime() - b.Longest_Date.getTime();
      });

      this.Reportinglist = this.IncompletePOList;
      this.monitoringLength = this.IncompletePOList.length;
      this.CloneJOList = this.IncompletePOList.slice();
      this.limitMonitorList();
      this.spinner.hide();
    });

    this.PODB_controller.search_PO_withoutCompletedGroupByCompany().then(data => {
      this.IncompletePOGroupedList = data;
      this.spinner.hide();
    });
    
    this.packing_controller.getPackingListList().then(data => {
      this.packingList = data;
      this.lengthPL = this.packingList.length;
      this.ClonePackingList = this.packingList.slice();
      this.limitPackingList();
    });

    this.part_controller.getPartList().then(data => {
      this.matSelectPart = data;
      this.spinner.hide();

      /*this.PODB_controller.getPOList().then(po => {
        po.forEach(so => {
          so.PO_Part_List.forEach(async part => {
            let temp = data.find(a=>{
              if(a.Part_Name == part.PO_Part_Name || a.Part_Name == part.PO_Part_Name.trim()){
                return true;
              }
            });

            if(temp){
              this.db.database.ref('Purchase Order/' + so.PO_No + '/Part List/' + part.ID + '/Part No').set(temp.ID);
            }
          });
        });
      });*/
    });

    this.StaffDB_controller.getStaffList().then(data => {
      this.Staff = data;
      this.spinner.hide();
    });
  }

  initcus(){
    this.CustomerDB_controller.getCustomerList().then(data => {
      this.Customer = data;
      this.lengthcus = this.Customer.length;
      this.CloneCustomer = this.Customer.slice();
      this.limitListcus();
      this.spinner.hide();
    });
  }

  initJob(){
    this.machineDB_controller.getMachineListForCompletedJob().then(data=>{
      this.Job = data;
      this.lengthJob = this.Job.length;
      this.CloneJob = this.Job.slice();
      this.limitListJob();
      this.spinner.hide();
    });
  }

  deletePO(POnumber) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this SO?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.PODB_controller.delete_PO(POnumber);
        this.setup();
      }
    });
  }

  filterStatus(){
    if(this.search)
      this.DS();
    else
      this.ClonePOList = this.POlist.slice();

    //this.ClonePOList = this.ClonePOList.filter(a=>a.Status.match(this.poStatus));

    this.ClonePOList =  this.ClonePOList.filter(u =>{
      for (const part of u.PO_Part_List) {
          if(part.JOStatus!=null && (part.JOStatus.includes(this.poStatus)))
            return true;
      }
      return false
    });

    this.length = this.ClonePOList.length;
    this.sortPOList();
    this.limitList();
    this.topPaginator.firstPage()
    this.bottomPaginator.firstPage()
  }

  filterMonitorStatus(){
    if(this.searchMonitor)
      this.DSMonitor();
    else
      this.CloneJOList = this.IncompletePOList.slice();

    this.CloneJOList =  this.CloneJOList.filter(u =>{
      for (const part of u.PO_Part_List) {
          if((part.PO_Status.includes(this.monitorStatus)))
            return true;
      }
      return false
    });
    this.monitoringLength = this.CloneJOList.length;
    this.sortJOList();
    this.limitMonitorList();
    this.topSOMonitoringPaginator.firstPage()
    this.bottomSOMonitoringPaginator.firstPage()
  }

  fs(){
    this.ClonePOList =  this.ClonePOList.filter(u => u.Status.match(this.poStatus));
  }

  calDateDifference(start, end){
    var time = end.getTime() - start.getTime();
    console.log(time);
  }

  DynamicSearchPO(partNum: string): void {
    this.search = partNum;
    this.ClonePOList = this.POlist.filter(u =>{
      const flag = (String(u.PO_No).toLowerCase().includes(this.search.toLowerCase()) || 
      String(u.Customer).toLowerCase().includes(this.search.toLowerCase()));
      return flag
    });
    this.length = this.ClonePOList.length;
    this.sortPOList();
    this.limitList();
    this.topPaginator.firstPage()
    this.bottomPaginator.firstPage()
  }

  DynamicSearchMonitoring(partNum: string): void {
    this.searchMonitor = partNum;
    this.CloneJOList = this.IncompletePOList.filter(u =>{
      const flag = (String(u.PO_No).toLowerCase().includes(this.searchMonitor.toLowerCase()) ||  String(u.Customer).toLowerCase().includes(this.searchMonitor.toLowerCase()));

      for (const part of u.PO_Part_List) {
        if(part.PO_Part_No && part.PO_Part_Name)
          if((part.PO_Part_No.toLowerCase().includes(this.searchMonitor.toLowerCase())|| (part.PO_Part_Name.toLowerCase().includes(this.searchMonitor.toLowerCase()))))
            return true;
      }

      return flag;
    });

    this.monitoringLength = this.CloneJOList.length;
    this.sortJOList();
    this.limitMonitorList();
    this.topSOMonitoringPaginator.firstPage()
    this.bottomSOMonitoringPaginator.firstPage()
  }

  DS() {
    this.ClonePOList = this.POlist.filter(u =>{
      const flag = (String(u.PO_No).toLowerCase().includes(this.search.toLowerCase()) || 
      String(u.Customer).toLowerCase().includes(this.search.toLowerCase()));
      return flag
    });
    this.length = this.ClonePOList.length;
  }

  DSMonitor() {
    this.CloneJOList = this.IncompletePOList.filter(u =>{
      const flag = (String(u.PO_No).toLowerCase().includes(this.searchMonitor.toLowerCase()) ||  String(u.Customer).toLowerCase().includes(this.searchMonitor.toLowerCase()));

      for (const part of u.PO_Part_List) {
        if(part.PO_Part_No && part.PO_Part_Name)
          if((part.PO_Part_No.toLowerCase().includes(this.searchMonitor.toLowerCase())|| (part.PO_Part_Name.toLowerCase().includes(this.searchMonitor.toLowerCase()))))
            return true;
      }

      return flag;
    });
    
    this.monitoringLength = this.CloneJOList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    var offset2 = this.pageSize * (this.pageIndex + 1);

    if(offset2 >= pageEvent.length){
      this.topPaginator.lastPage();
      this.bottomPaginator.lastPage();
    }
    else if(this.offset <= 0){
      this.topPaginator.firstPage();
      this.bottomPaginator.firstPage();
    }
    else{
      if (this.topPaginator.pageIndex < this.pageIndex) {
        this.topPaginator.nextPage();
      } 
      else if (this.topPaginator.pageIndex > this.pageIndex) {
        this.topPaginator.previousPage();
      }
  
      if (this.bottomPaginator.pageIndex < this.pageIndex) {
        this.bottomPaginator.nextPage();
      } 
      else if (this.bottomPaginator.pageIndex > this.pageIndex) {
        this.bottomPaginator.previousPage();
      }
    }

    if (this.search) {
      this.DS();
    } else {
      this.ClonePOList = this.POlist.slice();
    }
    
    this.fs();
    this.sortPOList();
    this.limitList();
  }

  monitoringPaginator(pageEvent: PageEvent) {
    this.monitoringPageSize = pageEvent.pageSize;
    this.monitoringPageIndex = pageEvent.pageIndex;
    this.monitoringOffset = this.monitoringPageSize * this.monitoringPageIndex;
    var offset2 = this.monitoringPageSize * (this.monitoringPageIndex + 1);

    if(offset2 >= pageEvent.length){
      this.topSOMonitoringPaginator.lastPage();
      this.bottomSOMonitoringPaginator.lastPage();
    }
    else if(this.monitoringOffset <= 0){
      this.topSOMonitoringPaginator.firstPage();
      this.bottomSOMonitoringPaginator.firstPage();
    }
    else{
      if (this.topSOMonitoringPaginator.pageIndex < this.monitoringPageIndex) {
        this.topSOMonitoringPaginator.nextPage();
      } 
      else if (this.topSOMonitoringPaginator.pageIndex > this.monitoringPageIndex) {
        this.topSOMonitoringPaginator.previousPage();
      }
  
      if (this.bottomSOMonitoringPaginator.pageIndex < this.monitoringPageIndex) {
        this.bottomSOMonitoringPaginator.nextPage();
      } 
      else if (this.bottomSOMonitoringPaginator.pageIndex > this.monitoringPageIndex) {
        this.bottomSOMonitoringPaginator.previousPage();
      }
    }

    if (this.searchMonitor) {
      this.DSMonitor();
    } else {
      this.CloneJOList = this.IncompletePOList.slice();
    }
    //this.fs();
    this.sortJOList();
    this.limitMonitorList();
  }

  reportingPaginator(pageEvent: PageEvent) {
    this.reportingPageSize = pageEvent.pageSize;
    this.reportingPageIndex = pageEvent.pageIndex;
    this.reportingOffset = this.reportingPageSize * this.reportingPageIndex;

    if (this.topReportingPaginator.pageIndex < this.reportingPageIndex) {
      this.topReportingPaginator.nextPage();
    } else if (this.topReportingPaginator.pageIndex > this.reportingPageIndex) {
      this.topReportingPaginator.previousPage();
    }

    if (this.bottomReportingPaginator.pageIndex < this.reportingPageIndex) {
      this.bottomReportingPaginator.nextPage();
    } else if (this.bottomReportingPaginator.pageIndex > this.reportingPageIndex) {
      this.bottomReportingPaginator.previousPage();
    }

    /*if (this.searchMonitor) {
      this.DSMonitor();
    } else {
      this.CloneJOList = this.IncompletePOList.slice();
    }*/
    //this.fs();
    this.sortReportingList();
    this.limitReportingList();
  }

  schedulingPaginator(pageEvent: PageEvent) {
    this.schedulingPageSize = pageEvent.pageSize;
    this.schedulingPageIndex = pageEvent.pageIndex;
    this.schedulingOffset = this.schedulingPageSize * this.schedulingPageIndex;

    if (this.topSchedulingPaginator.pageIndex < this.schedulingPageIndex) {
      this.topSchedulingPaginator.nextPage();
    } else if (this.topSchedulingPaginator.pageIndex > this.schedulingPageIndex) {
      this.topSchedulingPaginator.previousPage();
    }

    if (this.bottomSchedulingPaginator.pageIndex < this.schedulingPageIndex) {
      this.bottomSchedulingPaginator.nextPage();
    } else if (this.bottomSchedulingPaginator.pageIndex > this.schedulingPageIndex) {
      this.bottomSchedulingPaginator.previousPage();
    }

    /*if (this.searchMonitor) {
      this.DSMonitor();
    } else {
      this.CloneJOList = this.IncompletePOList.slice();
    }*/
    //this.fs();
    this.sortSchedulingList();
    this.limitSchedulingList();
  }

  limitList() {
    this.ClonePOList = this.ClonePOList.slice(this.offset, (this.offset + this.pageSize));
  }

  limitReportingList() {
    this.CloneReportingList = this.CloneReportingList.slice(this.offset, (this.offset + this.pageSize));
  }

  limitSchedulingList() {
    this.CloneScheduleList = this.CloneReportingList.slice(this.offset, (this.offset + this.pageSize));
  }

  limitMonitorList() {
    this.CloneJOList = this.CloneJOList.slice(this.monitoringOffset, (this.monitoringOffset + this.monitoringPageSize));
  }

  sortPOList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortJOList() {
    if (!this.sortMonitoredu.active || this.sortMonitoredu.direction === '') {
      return;
    }
    this.CloneJOList = this.IncompletePOList.sort((a, b) => {
      const isAsc = this.sortMonitoredu.direction === 'asc';
      switch (this.sortMonitoredu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'cus': return this.compareDate(a.Customer, b.Customer, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortDataMonitor(sort: Sort){
    this.sortMonitoredu = sort;
    this.CloneJOList = this.IncompletePOList.slice();
    if (this.searchMonitor) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.searchMonitor) {
      this.CloneJOList = this.CloneJOList.slice();
      this.limitMonitorList();
      return;
    }

    this.CloneJOList = this.CloneJOList.sort((a, b) => {
      const isAsc = this.sortMonitoredu.direction === 'asc';
      switch (this.sortMonitoredu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
         default: return 0;
      }
    });
    this.limitMonitorList();
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.ClonePOList = this.POlist.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.ClonePOList = this.POlist.slice();
      this.limitList();
      return;
    }

    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
         default: return 0;
      }
    });
    this.limitList();
  }

  sortReportingList() {
    if (!this.sortReportingedu.active || this.sortReportingedu.direction === '') {
      return;
    }
    this.CloneReportingList = this.Reportinglist.sort((a, b) => {
      const isAsc = this.sortReportingedu.direction === 'asc';
      switch (this.sortReportingedu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'cus': return this.compareDate(a.Customer, b.Customer, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortSchedulingList() {
    if (!this.sortReportingedu.active || this.sortReportingedu.direction === '') {
      return;
    }
    this.CloneReportingList = this.Reportinglist.sort((a, b) => {
      const isAsc = this.sortReportingedu.direction === 'asc';
      switch (this.sortReportingedu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'cus': return this.compareDate(a.Customer, b.Customer, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortReportingData(sort: Sort) {
    this.sortReportingedu = sort;
    this.CloneReportingList = this.Reportinglist.slice();
    
    if (!sort.active || sort.direction === '' && !this.search) {
      this.CloneReportingList = this.Reportinglist.slice();
      this.limitList();
      return;
    }

    this.CloneReportingList = this.CloneReportingList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortReportingedu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
         default: return 0;
      }
    });

    this.limitReportingList();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ViewShippingInformation(part){
    this.dialog.open(ShippingInformationComponent, {
      width: '80%',
      height: '80%',
      data: part
    });
  }
  ViewCheckOut(part){
    this.dialog.open(RawCheckOutInformationComponent, {
      width: '80%',
      height: '80%',
      data: part
    });
  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  viewOrder(po){
    this.dialog.open(OrdersComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: po
    });
  }

  DynamicSearchRawcus(cusname: string): void {
    this.searchcus = cusname;
    this.CloneCustomer = this.Customer.filter(u =>
      String(u.Customer_Name).toLowerCase().includes(this.searchcus.toLowerCase()));
    this.lengthcus = this.CloneCustomer.length;
    this.sortRawListcus();
    this.limitListcus();
    this.topPaginatorcus.firstPage()
    this.bottomPaginatorcus.firstPage()
  }
  DScus() {
    this.CloneCustomer = this.Customer.filter(u =>
      String(u.Customer_Name).toLowerCase().includes(this.searchcus.toLowerCase()));
    this.lengthcus = this.CloneCustomer.length;
  }

  paginatorcus(pageEvent: PageEvent) {
    this.pageSizecus = pageEvent.pageSize;
    this.pageIndexcus = pageEvent.pageIndex;
    this.offsetcus = this.pageSizecus * this.pageIndexcus;
    if (this.topPaginatorcus.pageIndex < this.pageIndexcus) {
      this.topPaginatorcus.nextPage();
    } else if (this.topPaginatorcus.pageIndex > this.pageIndexcus) {
      this.topPaginatorcus.previousPage();
    }
    if (this.bottomPaginatorcus.pageIndex < this.pageIndexcus) {
      this.bottomPaginatorcus.nextPage();
    } else if (this.bottomPaginatorcus.pageIndex > this.pageIndexcus) {
      this.bottomPaginatorcus.previousPage();
    }
    if (this.searchcus) {
      this.DScus();
    } else {
      this.CloneCustomer = this.Customer.slice();
    }
    this.sortRawListcus();
    this.limitListcus();
  }
  limitListcus() {
    this.CloneCustomer = this.CloneCustomer.slice(this.offsetcus, (this.offsetcus + this.pageSizecus));
  }
  sortRawListcus() {
    if (!this.sorteducus.active || this.sorteducus.direction === '') {
      return;
    }
    this.CloneCustomer = this.CloneCustomer.sort((a, b) => {
      const isAsc = this.sorteducus.direction === 'asc';
      switch (this.sorteducus.active) {
        case 'name': return this.compare(a.Customer_Name, b.Customer_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        default: return 0;
      }
    });
  }
  sortDatacus(sort: Sort) {
    this.sorteducus = sort;
    this.CloneCustomer = this.Customer.slice();
    if (this.searchcus) {
      this.DScus();
    }
    if (!sort.active || sort.direction === '' && !this.searchcus) {
      this.CloneCustomer = this.Customer.slice();
      this.limitListcus();
      return;
    }

    this.CloneCustomer = this.CloneCustomer.sort((a, b) => {
      const isAsc = this.sorteducus.direction === 'asc';
      switch (this.sorteducus.active) {
        case 'name': return this.compare(a.Customer_Name, b.Customer_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        default: return 0;
      }
    });
    this.limitListcus();
  }

  ViewCustomer(Customer: Customer){

    const dialogRef = this.dialog.open(AddCustomerComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      data: {...Customer}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.CustomerDB_controller.update_Customer(result, this.email);
        this.setup();
      }
    });
  }
  AddCustomer(){
    const dialogRef = this.dialog.open(AddCustomerComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '90%',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.CustomerDB_controller.add_Customer(result, this.email);
        this.setup();
      }
    });
  }
  deletecus(cus : Customer){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this Customer? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.CustomerDB_controller.delete_Customer(cus.Customer_ID);
        this.setup();
      }
    })
  }

  AddPO(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = '100%';
    dialogConfig.width = '100%';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.maxHeight = '100vh';
    const position = {
      top: '2%'
    };
    //dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(AddPOComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    })
  }

  viewDetail(PO){
    const dialogRefupdatePart = this.dialog.open(DetailPOPageComponent, {
      data: PO,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      autoFocus: false
    });

    dialogRefupdatePart.afterClosed().subscribe(result => {
      this.setup();
    });
    /*const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    //dialogConfig.data = PO;
    this.dialog.open(DetailPOPageComponent, dialogConfig).afterClosed().subscribe(result => {
      
    })*/
  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();

    for (const po of this.POlist) {
      const info ={
        "PO":po.PO_No || "-",
        "Part List": po.PO_Part_List.map(a=>a.PO_Part_No).join('  |  '),
        "Part List Name": po.PO_Part_List.map(a=>a.PO_Part_Name).join(' |  '),
        "Production Quantity": po.PO_Part_List.map(a=>a.PO_Part_Qty).join(' |  '),
        "PO Quantity": po.PO_Part_List.map(a=>a.POQuantity).join(' |  '),
        "Reference": po.PO_Part_List.map(a=>a.Reference).join(' |  '),
        "POS": po.PO_Part_List.map(a=>a.POS).join(' |  '),
        "Status": po.PO_Part_List.map(a=>a.PO_Status).join(' |  '),
        "Created Date":po.Created_Date
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'PO'+new Date().getTime());
    this.spinner.hide();

  }

  printReport() {
    //const exportInformation = [];
    this.spinner.show();
    
    var html = '<html  xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><title>Test Print</title><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/><style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {padding: 0.50rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>';
    html += '<h1>ENGINEERING SCHEDULE LOG </h1><br><p>DATE RELEASED: ' + this.dateService.convertDateIntoISO8601(new Date(this.startDate)) + '</p><table class="table table-bordered"><thead><tr><th scope="col">NO</th><th scope="col">PO NO</th><th scope="col">PART NO</th><th scope="col">DESCRIPTION</th><th scope="col">Qty</th><th scope="col">ETD</th><th scope="col">LEAD TIME</th><th scope="col">UNFOLD</th><th scope="col">REMARKS</th><th scope="col">CHECKED BY</th><th scope="col">DATE</th></tr></thead><tbody>';
    
    this.CloneReportingList.forEach(async po => {
      
      html += '<tr style="background-color: grey;"><td></td><td>SO NO</td><td colspan="9">' + po.PO_No + '</td></tr>';
      var index = 1;

      await po.PO_Part_List.forEach(jo => {
        html += '<tr><td>' + index.toString() +'</td><td>' + po.Customer_PO + '</td><td>' + jo.PO_Part_No + '</td><td>' + jo.PO_Part_Name + '</td><td>' + jo.POQuantity + '</td><td>' + this.dateService.convertDateIntoISO8601(jo.EndDate) + '</td><td>' + jo.Date_Diff + ' DAYS</td><td></td><td></td><td></td><td></td></tr>';
        index++;
      });
    });
    
    html += '</tbody></table></html>';

    //let fileName = "Engineering_Schedule" + this.dateService.convertDateIntoISO8601(new Date(this.startDate)) + ".xls";

    setTimeout(function(){
      var printWindow = window.open('', '', 'height=800,width=800');
      printWindow.document.write(html)
      printWindow.document.close();
      setTimeout(function(){printWindow.focus();printWindow.print();printWindow.close();}, 500);
    }, 1000);

    this.spinner.hide();

    /*setTimeout(function(){
      var a = document.createElement('a');
      var data_type = 'data:application/vnd.ms-excel';
      a.href = data_type + ', ' + encodeURIComponent(html);
      a.download = fileName;
      a.click();
      return (a);
      //window.open('data:application/vnd.ms-excel,' + encodeURIComponent(html));
    }, 1000);*/
    
    this.spinner.hide();
  }

  exportReportToExcel() {
    //const exportInformation = [];
    this.spinner.show();
    let reportList = this.CloneReportingList.sort((a, b)=> {return this.compare(a.Customer, b.Customer, true)});

    var html = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><title>Test Print</title><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/><style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {border:1pt; padding: 0.50rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>';
    html += '<h1>DELIVERY SCHEDULE LOG </h1><br><p>DATE RELEASED: ' + this.dateService.convertDateIntoISO8601(new Date(this.startDate)) + ' to ' + this.dateService.convertDateIntoISO8601(new Date(this.endDate)) + '</p><table class="table table-bordered" border="1" style="border-collapse: collapse;"><thead><tr><th scope="col">NO</th><th scope="col">PO NO</th><th scope="col">PART NO</th><th scope="col">DESCRIPTION</th><th scope="col">Qty</th><th scope="col">ETD</th><th scope="col">REMAINING TIME</th><th scope="col">UNFOLD</th><th scope="col">REMARKS</th><th scope="col">CHECKED BY</th><th scope="col">DATE</th></tr></thead><tbody>';
    
    reportList.forEach(async po => {
      html += '<tr bgcolor="#CCD1D1"><td></td><td>SO NO: ' + po.PO_No + '</td><td colspan="9">Customer: ' + po.Customer + '</td></tr>';
      var index = 1;

      await po.PO_Part_List.forEach(jo => {
        html += '<tr><td>' + index.toString() +'</td><td>' + po.Customer_PO + '</td><td>' + jo.PO_Part_No + '</td><td>' + jo.PO_Part_Name + '</td><td>' + jo.POQuantity + '</td><td>' + this.dateService.convertDateIntoISO8601(jo.EndDate) + '</td><td>' + jo.Date_Diff + ' DAYS</td><td></td><td></td><td></td><td></td></tr>';
        index++;
      });
    });
    
    html += '</tbody></table></html>';

    let fileName = "Delivery_Schedule" + this.dateService.convertDateIntoISO8601(new Date(this.startDate)) + ' to ' + this.dateService.convertDateIntoISO8601(new Date(this.endDate)) + ".xls";

    setTimeout(function(){
      var a = document.createElement('a');
      var data_type = 'data:application/vnd.ms-excel; base64';
      a.href = data_type + ', ' + btoa(unescape(encodeURIComponent(html)));
      a.download = fileName;
      a.click();
      return (a);
      //window.open('data:application/vnd.ms-excel,' + encodeURIComponent(html));
    }, 1000);
    
    this.spinner.hide();
  }

  printSchedule() {
    //const exportInformation = [];
    this.spinner.show();
    let endDate = new Date(this.endDate);
    let mondays = this.dateService.getAllInstancesOfDayInMonth(endDate, 1);
    let mondays2 = this.dateService.getAllInstancesOfDayInMonth(new Date([endDate.getFullYear(),endDate.getMonth()+1,1].join('/')), 1);
    let mondays3 = this.dateService.getAllInstancesOfDayInMonth(new Date([endDate.getFullYear(),endDate.getMonth()+2,1].join('/')), 1);
    let len = mondays.length + mondays2.length + mondays3.length;

    var html = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
    html += '<head><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>';
    html += '<x:Name>Test Sheet</x:Name><x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
    html += '</x:ExcelWorksheets></x:ExcelWorkbook></xml>';
    html += '<style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {padding: 0.50rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>';
    html += '<h1>PRODUCTION SCHEDULE</h1><br><p>ISSUE DATE: ' + this.dateService.convertDateIntoISO8601(new Date(this.endDate)) + '</p><table class="table table-bordered"><thead><tr><th rowspan="2">#</th><th rowspan="2">DESCRIPTION</th><th rowspan="2">QTY</th><th rowspan="2">LEADTIME</th><th>DATE</th><th colspan="'+mondays.length+'">CURRENT MONTH</th><th colspan="'+mondays2.length+'">NEXT MONTH</th><th colspan="'+mondays3.length+'">MONTH AFTER</th><th rowspan="2">ETD</th></tr><tr><th>'+this.dateService.convertDateIntoISO8601NoYear(new Date(this.endDate))+'</th>';
    
    mondays.forEach(mon => {
      html += '<th>'+this.dateService.convertDateIntoISO8601NoYear(mon)+'</th>';
    });

    mondays2.forEach(mon => {
      html += '<th>'+this.dateService.convertDateIntoISO8601NoYear(mon)+'</th>';
    });

    mondays3.forEach(mon => {
      html += '<th>'+this.dateService.convertDateIntoISO8601NoYear(mon)+'</th>';
    });
    
    html += '</tr></thead><tbody>';
    var index = 1;
    
    this.CloneScheduleList.forEach(async po => {
      await po.PO_Part_List.forEach(jo => {
        for(var j=0; j<3; j++){
          if(j == 0){
            html += '<tr><td rowspan="3">' + index.toString() +'</td><td>' + po.PO_No + '</td><td rowspan="3">' + jo.POQuantity + '</td><td rowspan="3">' + jo.Date_Diff + ' DAYS</td><td rowspan="3">' + this.dateService.convertDateIntoISO8601(new Date(this.endDate)) + '</td>';
        
            for(var i=0; i<len; i++){
              html += '<td></td>';
            }
            
            html += '<td rowspan="3">'+this.dateService.convertDateIntoISO8601(new Date(jo.EndDate))+'</td></tr>';
          }
          else if(j == 1){
            html += '<tr><td>' + jo.PO_Part_No + '</td>';
        
            for(var i=0; i<len; i++){
              html += '<td></td>';
            }

            html += '</tr>';
          }
          else if(j == 2){
            html += '<tr><td>' + jo.PO_Part_Name + '</td>';
        
            for(var i=0; i<len; i++){
              html += '<td></td>';
            }

            html += '</tr>';
          }
        }
        
        index++;
      });
    });
    
    html += '</tbody></table></html>';
    
    setTimeout(function(){
      var printWindow = window.open('', '', 'height=800,width=800');
      printWindow.document.write(html)
      printWindow.document.close();
      setTimeout(function(){printWindow.focus();printWindow.print();printWindow.close();}, 500);
    }, 1000);
    
    this.spinner.hide();
  }

  exportScheduleToExcel() {
    //const exportInformation = [];
    this.spinner.show();
    let mondays = [];
    let months = [];
    let mondays2 = [];
    let endDate = new Date(this.endDate);
    let current = endDate;
    let currentMonth = current.getMonth();
    let len = 13;

    for(var index=0; index<13; index++){
      current = this.dateService.getNextMonday(current);
      mondays.push(current);

      if(new Date(current).getMonth() != currentMonth || index == 0){
        currentMonth = current.getMonth();
        var data = {
          "month": currentMonth,
          "weeks": 1
        };
        mondays2.push(data);
      }
      else{
        let weeks = mondays2[mondays2.length-1].weeks;
        mondays2[mondays2.length-1].weeks = weeks + 1;
      }
    }
    
    /*let mondays = this.dateService.getAllInstancesOfDayInMonth(endDate, 1);
    let mondays2 = this.dateService.getAllInstancesOfDayInMonth(new Date([endDate.getFullYear(),endDate.getMonth()+1,1].join('/')), 1);
    let mondays3 = this.dateService.getAllInstancesOfDayInMonth(new Date([endDate.getFullYear(),endDate.getMonth()+2,1].join('/')), 1);
    let len = mondays.length + mondays2.length + mondays3.length;*/

    var html = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
    html += '<head><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>';
    html += '<x:Name>Test Sheet</x:Name><x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
    html += '</x:ExcelWorksheets></x:ExcelWorkbook></xml>';
    html += '<style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {padding: 0.50rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>';
    html += '<h1>PRODUCTION SCHEDULE</h1><br><p>ISSUE DATE: ' + this.dateService.convertDateIntoISO8601(new Date(this.endDate)) + '</p><table class="table table-bordered" border="1" style="border-collapse: collapse;"><thead><tr><th rowspan="2">#</th><th rowspan="2">DESCRIPTION</th><th rowspan="2">QTY</th><th rowspan="2">LEADTIME</th><th>DATE</th>';

    for(var m=0; m<mondays2.length; m++){
      if(months.length == 0){
        html += '<th colspan="'+mondays2[m].weeks.toString()+'">CURRENT MONTH</th>';
        months.push(mondays2[m].month);
      }
      else if(months.length == 1){
        html += '<th colspan="'+mondays2[m].weeks.toString()+'">NEXT MONTH</th>';
        months.push(mondays2[m].month);
      }
      else{
        html += '<th colspan="'+mondays2[m].weeks.toString()+'">MONTH AFTER</th>';
      }
    }
    
    html += '<th rowspan="2">ETD</th></tr><tr><th>'+this.dateService.convertDateIntoISO8601NoYear(new Date(this.endDate))+'</th>';
    
    mondays.forEach(mon => {
      html += '<th>'+this.dateService.convertDateIntoISO8601NoYear(mon)+'</th>';
    });

    /*mondays.forEach(mon => {
      html += '<th>'+this.dateService.convertDateIntoISO8601NoYear(mon)+'</th>';
    });

    mondays2.forEach(mon => {
      html += '<th>'+this.dateService.convertDateIntoISO8601NoYear(mon)+'</th>';
    });

    mondays3.forEach(mon => {
      html += '<th>'+this.dateService.convertDateIntoISO8601NoYear(mon)+'</th>';
    });*/
    
    html += '</tr></thead><tbody>';
    var index = 1;
    
    this.CloneScheduleList.forEach(async po => {
      await po.PO_Part_List.forEach(jo => {
        for(var j=0; j<3; j++){
          if(j == 0){
            html += '<tr><td rowspan="3">' + index.toString() +'</td><td bgcolor="#CCD1D1">' + po.PO_No + '</td><td rowspan="3">' + jo.POQuantity + '</td><td rowspan="3">' + jo.Date_Diff + ' DAYS</td><td rowspan="3">' + this.dateService.convertDateIntoISO8601(new Date(this.endDate)) + '</td>';
        
            for(var i=0; i<len; i++){
              html += '<td></td>';
            }
            
            html += '<td rowspan="3">'+this.dateService.convertDateIntoISO8601(new Date(jo.EndDate))+'</td></tr>';
          }
          else if(j == 1){
            html += '<tr><td>' + jo.PO_Part_No + '</td>';
        
            for(var i=0; i<len; i++){
              html += '<td></td>';
            }

            html += '</tr>';
          }
          else if(j == 2){
            html += '<tr><td>' + jo.PO_Part_Name + '</td>';
        
            for(var i=0; i<len; i++){
              html += '<td></td>';
            }

            html += '</tr>';
          }
        }
        
        index++;
      });
    });
    
    html += '</tbody></table></html>';
    let fileName = "Production_Schedule" + this.dateService.convertDateIntoISO8601(new Date(this.endDate)) + ".xls";

    setTimeout(function(){
      var a = document.createElement('a');
      var data_type = 'data:application/vnd.ms-excel;charset=UTF-8; base64';
      a.href = data_type + ', ' + window.btoa(unescape(encodeURIComponent(html)));
      a.download = fileName;
      a.click();
      return (a);
      //window.open('data:application/vnd.ms-excel,' + encodeURIComponent(html));
    }, 1000);
    
    this.spinner.hide();
  }

  exportToExcelCustomer() {
    const exportInformation = [];
    this.spinner.show();
    const cuss = this.Customer.sort((a,b)=>{
      return (a.Customer_Name < b.Customer_Name ? -1 : 1)* (true ? 1 : -1);
    }
      );
    for (const cus of cuss) {
      const info ={
        "Client name":cus.Customer_Name || "-",
        "Address":cus.Address || "-",
        "Contact":cus.Contact || "-",
        "Email":cus.Email || "-",
        "Person In Charge":cus.Person_In_Charge || "-",
        "Created Date":cus.Created_Date || "-",
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'Client'+new Date().getTime());
    this.spinner.hide();

  }

  async editPart(part): Promise<void> {
    var updatePart = new Part();

    updatePart = await this.matSelectPart.find(a=>a.ID == part);
    //updatePart = part;
    const dialogRefupdatePart = this.dialog.open(UpdatePartComponent, {
      data: updatePart,
      width: '90%',
      height: '90%',
    });

    dialogRefupdatePart.afterClosed().subscribe(async result => {
      if (typeof result !== 'undefined') {
        updatePart = result;
        this.spinner.show();
        await this.partDB_controllers.add_or_update_Part(updatePart);
        setTimeout(() => {
          this.toast.success(updatePart.Part_Name + " has been updated successfully!","Updated Successfully")
          this.setup();
        }, 2000);
      }
    });
  }

  msToTime(end,start) {
    let duration = parseFloat(end)-parseFloat(start);
    if(duration > 0){
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      let hrs = (hours < 10) ? "0" + hours : hours;
      let min = (minutes < 10) ? "0" + minutes : minutes;
      let sec = (seconds < 10) ? "0" + seconds : seconds;
      return hrs + ":" + min + ":" + sec;
    }
    return 0;

  }

  ChangeDate(formindex) {
    this.startDate = formindex.value;
    this.selectedStartDate = formindex.value;
    this.CloneReportingList = this.Reportinglist.filter(a=> this.dateService.convertDateIntoYearMonthDay(a.Created_Date) == this.dateService.convertDateIntoYearMonthDay(new Date(formindex.value)));

    this.CloneReportingList.forEach(item => {
      item.PO_Part_List.forEach(data => {
        data.Date_Diff = Math.ceil((data.EndDate.getTime() - this.startDate.getTime()) / (24 * 60 * 60 * 1000)).toString();;
      });
    });
  }

  ChangeDate2(formindex) {
    this.endDate = formindex.value;
    this.selectedEndDate2 = formindex.value;
    this.CloneReportingList = this.Reportinglist.filter(a => this.dateService.convertDateIntoYearMonthDay(a.Longest_Date) >= this.dateService.convertDateIntoYearMonthDay(new Date(this.startDate)) && this.dateService.convertDateIntoYearMonthDay(a.Longest_Date) <= this.dateService.convertDateIntoYearMonthDay(new Date(this.endDate)));
    this.CloneReportingList.forEach(item => {
      item.PO_Part_List.forEach(data => {
        data.Date_Diff = Math.ceil((data.EndDate.getTime() - this.startDate.getTime()) / (24 * 60 * 60 * 1000)).toString();;
      });
    });
  }

  ChangeScheduleDate(formindex){
    this.endDate = formindex.value;
    this.selectedEndDate = formindex.value;
    this.CloneScheduleList = this.Reportinglist.filter(a=> this.dateService.convertDateIntoYearMonthDay(a.Created_Date) == this.dateService.convertDateIntoYearMonthDay(new Date(formindex.value)));
  }

  hoursDisplay(hr){
    if(hr > 0){
      let min = hr - parseFloat(hr.toString().split('.')[0])
      min = min * 60
      let sec = min - parseFloat(min.toString().split('.')[0])
      sec = sec * 60 ;
      let hrs1 = (hr < 10) ? "0" + hr.toString().split('.')[0] : hr.toString().split('.')[0];
      let min1 = (min < 10) ? "0" + min.toString().split('.')[0] : min.toString().split('.')[0];
      let sec1 = (sec < 10) ? "0" + sec.toString().split('.')[0] : sec.toString().split('.')[0];
      return hrs1 + ":" + min1 + ":" + sec1;
    }
    return 0;
  }

  DynamicSearchJob(job: string): void {
    this.searchJob = job;
    this.CloneJob = this.Job.filter(u =>
      String(u.Machine_Number).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_PO_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.PartName).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_Part_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.CycleTime).toLowerCase().includes(this.searchJob.toLowerCase())
      );
    this.lengthJob = this.CloneJob.length;
    this.sortJob();
    this.limitListJob();
    this.topPaginatorJob.firstPage()
    this.bottomPaginatorJob.firstPage()
  }
  DSJob() {
    this.CloneJob = this.Job.filter(u =>
      String(u.Machine_Number).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_PO_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.PartName).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_Part_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.CycleTime).toLowerCase().includes(this.searchJob.toLowerCase())
      );
      this.lengthJob = this.CloneJob.length;

  }

  paginatorJob(pageEvent: PageEvent) {
    this.pageSizeJob = pageEvent.pageSize;
    this.pageIndexJob = pageEvent.pageIndex;
    this.offsetJob = this.pageSizeJob * this.pageIndexJob;
    if (this.topPaginatorJob.pageIndex < this.pageIndexJob) {
      this.topPaginatorJob.nextPage();
    } else if (this.topPaginatorJob.pageIndex > this.pageIndexJob) {
      this.topPaginatorJob.previousPage();
    }
    if (this.bottomPaginatorJob.pageIndex < this.pageIndexJob) {
      this.bottomPaginatorJob.nextPage();
    } else if (this.bottomPaginatorJob.pageIndex > this.pageIndexJob) {
      this.bottomPaginatorJob.previousPage();
    }
    if (this.searchJob) {
      this.DSJob();
    } else {
      this.CloneJob = this.Job.slice();
    }
    this.sortJob();
    this.limitListJob();
  }
  limitListJob() {
    this.CloneJob = this.CloneJob.slice(this.offsetJob, (this.offsetJob + this.pageSizeJob));
  }
  sortJob() {
    if (!this.sorteduJob.active || this.sorteduJob.direction === '') {
      return;
    }
    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'po': return this.compare(a.Machine_Schedule_PO_No, b.Machine_Schedule_PO_No, isAsc);
        case 'machine': return this.compare(Number(a.Machine_Number.match(/(\d+)/g)[0]), Number(b.Machine_Number.match(/(\d+)/g)[0]), isAsc);
        case 'part': return this.compare(a.Machine_Schedule_Part_No, b.Machine_Schedule_Part_No, isAsc);
        case 'partName': return this.compare(a.PartName, b.PartName, isAsc);
        case 'PredefinedCycle': return this.compare(a.CycleTime, b.CycleTime, isAsc);
        case 'startTime': return this.compareDate(a.DateFormatStart, b.DateFormatStart, isAsc);
        case 'endTime': return this.compareDate(a.DateFormatEnd, b.DateFormatEnd, isAsc);
        case 'presetTime': return this.compare(parseFloat(a.DatePresettingEnd)-parseFloat(a.DatePresettingStart),parseFloat(b.DatePresettingEnd)-parseFloat(b.DatePresettingStart), isAsc);
        case 'downTime': return this.compare(a.TotalDownTime, b.TotalDownTime, isAsc);
        default: return 0;
      }
    });
  }
  sortDataJob(sort: Sort) {
    this.sorteduJob = sort;
    this.CloneJob = this.Job.slice();
    if (this.searchJob) {
      this.DSJob();
    }
    if (!sort.active || sort.direction === '' && !this.searchJob) {
      this.CloneJob = this.Job.slice();
      this.limitListJob();
      return;
    }

    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'po': return this.compare(a.Machine_Schedule_PO_No, b.Machine_Schedule_PO_No, isAsc);
        case 'machine': return this.compare(Number(a.Machine_Number.match(/(\d+)/g)[0]), Number(b.Machine_Number.match(/(\d+)/g)[0]), isAsc);
        case 'part': return this.compare(a.Machine_Schedule_Part_No, b.Machine_Schedule_Part_No, isAsc);
        case 'partName': return this.compare(a.PartName, b.PartName, isAsc);
        case 'PredefinedCycle': return this.compare(a.CycleTime, b.CycleTime, isAsc);
        case 'startTime': return this.compareDate(a.DateFormatStart, b.DateFormatStart, isAsc);
        case 'endTime': return this.compareDate(a.DateFormatEnd, b.DateFormatEnd, isAsc);
        case 'presetTime': return this.compare(parseFloat(a.DatePresettingEnd)-parseFloat(a.DatePresettingStart),parseFloat(b.DatePresettingEnd)-parseFloat(b.DatePresettingStart), isAsc);
        case 'downTime': return this.compare(a.TotalDownTime, b.TotalDownTime, isAsc);
         default: return 0;
      }
    });
    this.limitListJob();
  }

  exportToExcelJob() {
    const exportInformation = [];
    this.spinner.show();
    const jo = this.Job.sort((a,b)=>{
      return (Number(a.Machine_Number.match(/(\d+)/g)[0]) - Number((b.Machine_Number.match(/(\d+)/g)[0])));})
    for (const j of jo) {
      const info ={
        "Purchase Order":j.Machine_Schedule_PO_No || "-",
        "Machine":j.Machine_Number || "-",
        "Part No":j.Machine_Schedule_Part_No || "-",
        "Part Name":j.PartName || "-",
        "Cycle Time":j.CycleTime || "-",
        "Start Time":j.DateFormatStart || "-",
        "End Time":j.DateFormatEnd || "-",
        "Presetting Time": this.msToTime(j.DatePresettingEnd,j.DatePresettingStart) || "-",
        "Downtime Time":this.hoursDisplay(j.TotalDownTime) || "-",

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'Client'+new Date().getTime());
    this.spinner.hide();

  }

  view(job:PartTracker){
    this.dialog.open(DowntimeDetailsComponent, {
      width: '80%',
      height: 'auto',
      data: job.Schedule,
      disableClose:true
    });
  }

  viewDrawing(url){
    window.open(url, '_blank');
  }

  viewDrawing2(part: PartTracker){
    this.dialog.open(ViewPartComponent, {
      width: '80%',
      height: 'auto',
      data: part,
      disableClose:true
    });
  }

  printJT(machine: PartTracker, PO_No, Customer_PO, Person_In_Charge){
    this.spinner.show();
    $("#forPrintJT").find("remarksTP").val('');

    $("#forPrintJT").find("#description").text(machine.PO_Part_Name);
    $("#forPrintJT").find("#salesOrder").html(machine.ID);
    $("#forPrintJT").find("#partNo").text(machine.PO_Part_No);
    $("#forPrintJT").find("#poNo").text(Customer_PO);
    $("#forPrintJT").find("#quantity").text(machine.POQuantity + " " + (machine.UOM != null ? machine.UOM : ''));
    $("#forPrintJT").find("#projectOwner").text(Person_In_Charge);
    $("#forPrintJT").find("#dateReleased").text((machine.StartDate.getDate() < 10 ? 0 + machine.StartDate.getDate().toString() : machine.StartDate.getDate().toString()) + "/" + ((machine.StartDate.getMonth() + 1) < 10 ? 0 + (machine.StartDate.getMonth() + 1).toString() : (machine.StartDate.getMonth() + 1).toString()) + "/" + machine.StartDate.getFullYear().toString());
    $("#forPrintJT").find("#etd").text((machine.EndDate.getDate() < 10 ? 0 + machine.EndDate.getDate().toString() : machine.EndDate.getDate().toString())   + "/" + ((machine.EndDate.getMonth() + 1) < 10 ? 0 + (machine.EndDate.getMonth() + 1).toString() : (machine.EndDate.getMonth() + 1).toString()) + "/" + machine.EndDate.getFullYear().toString());

    $("#forPrintJT3").find("#drawingNo").text(machine.PO_Part_No);
    $("#forPrintJT3").find("#footer").text("RCET-QA-FR-023-02");

    if(machine.Process!=null && machine.Process.includes("POWDER COATING")){
      $("#forPrintJT").find("#remarksTP").html(machine.Outsources.join(', '));
    }
    else if(machine.Process!=null && machine.Process.includes("TREATMENT")){
      $("#forPrintJT").find("#remarksTP").html(machine.Outsources.join(', '));
    }

    JsBarcode("#barcode", machine.ID, {
      height:25,
      width:1,
      displayValue: false
    });

    setTimeout(function(){
      var divContents = $("#forPrintJT").html();
      var divContents2 = $("#forPrintJT2").html();
      var divContents3 = $("#forPrintJT3").html();
      var printWindow = window.open('', '', 'height=800,width=800');
      printWindow.document.write('<html><head><title>Test Print</title>')
      printWindow.document.write('<style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {padding: 0.50rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>');
      printWindow.document.write(divContents);

      printWindow.document.write(divContents3);
      //printWindow.document.write('<p style="page-break-after:always;"></p>');
      printWindow.document.write(divContents2);
      // printWindow.document.write('<img src="../../../../assets/JT_Page2.png" height="150%" width="100%"/>');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      setTimeout(function(){printWindow.focus();printWindow.print();printWindow.close();}, 500);
    }, 1000);
    this.spinner.hide();
  }

  async printJTs(smachine: PurchaseOrder){
    if(smachine.PO_Part_List != null && smachine.PO_Part_List.length > 0){
      var printWindow = window.open('', '', 'height=800,width=800');
      printWindow.document.write('<html><head><title>Test Print</title>');
      printWindow.document.write('<style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {padding: 0.50rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>');
      //printWindow.document.write('<style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse;} .table th, .table td {padding: 0.70rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;}</style></head><body>');
      await smachine.PO_Part_List.forEach(data => {
        $("#forPrintJT").find("remarksTP").val('');
        $("#forPrintJT").find("#description").text(data.PO_Part_Name);
        $("#forPrintJT").find("#salesOrder").html(data.ID);
        $("#forPrintJT").find("#partNo").text(data.PO_Part_No);
        $("#forPrintJT").find("#poNo").text(smachine.Customer_PO);
        $("#forPrintJT").find("#quantity").text(data.POQuantity + " " + (data.UOM != null ? data.UOM : ''));
        $("#forPrintJT").find("#projectOwner").text(smachine.Person_In_Charge);
        $("#forPrintJT").find("#dateReleased").text((data.StartDate.getDate() < 10 ? 0 + data.StartDate.getDate().toString() : data.StartDate.getDate().toString()) + "/" + ((data.StartDate.getMonth() + 1) < 10 ? 0 + (data.StartDate.getMonth() + 1).toString() : (data.StartDate.getMonth() + 1).toString()) + "/" + data.StartDate.getFullYear().toString());
        $("#forPrintJT").find("#etd").text((data.EndDate.getDate() < 10 ? 0 + data.EndDate.getDate().toString() : data.EndDate.getDate().toString())   + "/" + ((data.EndDate.getMonth() + 1) < 10 ? 0 + (data.EndDate.getMonth() + 1).toString() : (data.EndDate.getMonth() + 1).toString()) + "/" + data.EndDate.getFullYear().toString());

        $("#forPrintJT3").find("#drawingNo").text(data.PO_Part_No);
        $("#forPrintJT3").find("#footer").text("RCET-QA-FR-023-02");

        if(data.Process!=null && data.Process.includes("POWDER COATING")){
          $("#forPrintJT").find("#remarksTP").html(data.Outsources.join(', '));
        }
        else if(data.Process!=null && data.Process.includes("TREATMENT")){
          $("#forPrintJT").find("#remarksTP").html(data.Outsources.join(', '));
        }

        JsBarcode("#barcode", data.ID, {
          height:25,
          width:1,
          displayValue: false
        });

        var divContents = $("#forPrintJT").html();
        var divContents2 = $("#forPrintJT2").html();
        var divContents3 = $("#forPrintJT3").html();
    
        printWindow.document.write(divContents);
        printWindow.document.write(divContents3);
        //printWindow.document.write('<p style="page-break-after:always;"></p>');
        printWindow.document.write(divContents2);
        // printWindow.document.write('<img src="../../../../assets/JT_Page2.png" height="150%" width="100%"/>');
        printWindow.document.write('<p style="page-break-after:always;"></p>');
      });

      printWindow.document.write('</body></html>');
      printWindow.document.close();
      setTimeout(function(){printWindow.focus();printWindow.print();printWindow.close();}, 1000);
    }
  }

  async PrintAll(){
    var printWindow = window.open('', '', 'height=800,width=800');
    printWindow.document.write('<html><head><title>Test Print</title>');
    printWindow.document.write('<style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {padding: 0.50rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>');

    await this.deleteList.forEach(async data1 => {
      if(data1.PO_Part_List != null && data1.PO_Part_List.length > 0){
        //printWindow.document.write('<style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse;} .table th, .table td {padding: 0.70rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;}</style></head><body>');
        await data1.PO_Part_List.forEach(data => {
          $("#forPrintJT").find("remarksTP").val('');
          $("#forPrintJT").find("#description").text(data.PO_Part_Name);
          $("#forPrintJT").find("#salesOrder").html(data.ID);
          $("#forPrintJT").find("#partNo").text(data.PO_Part_No);
          $("#forPrintJT").find("#poNo").text(data1.Customer_PO);
          $("#forPrintJT").find("#quantity").text(data.POQuantity + " " + (data.UOM != null ? data.UOM : ''));
          $("#forPrintJT").find("#projectOwner").text(data1.Person_In_Charge);
          $("#forPrintJT").find("#dateReleased").text((data.StartDate.getDate() < 10 ? 0 + data.StartDate.getDate().toString() : data.StartDate.getDate().toString()) + "/" + ((data.StartDate.getMonth() + 1) < 10 ? 0 + (data.StartDate.getMonth() + 1).toString() : (data.StartDate.getMonth() + 1).toString()) + "/" + data.StartDate.getFullYear().toString());
          $("#forPrintJT").find("#etd").text((data.EndDate.getDate() < 10 ? 0 + data.EndDate.getDate().toString() : data.EndDate.getDate().toString())   + "/" + ((data.EndDate.getMonth() + 1) < 10 ? 0 + (data.EndDate.getMonth() + 1).toString() : (data.EndDate.getMonth() + 1).toString()) + "/" + data.EndDate.getFullYear().toString());
    
          $("#forPrintJT3").find("#drawingNo").text(data.PO_Part_No);
          $("#forPrintJT3").find("#footer").text("RCET-QA-FR-023-02");

          if(data.Process!=null && data.Process.includes("POWDER COATING")){
            $("#forPrintJT").find("#remarksTP").html(data.Outsources.join(', '));
          }
          else if(data.Process!=null && data.Process.includes("TREATMENT")){
            $("#forPrintJT").find("#remarksTP").html(data.Outsources.join(', '));
          }
  
          JsBarcode("#barcode", data.ID, {
            height:25,
            width:1,
            displayValue: false
          });
  
          var divContents = $("#forPrintJT").html();
          var divContents2 = $("#forPrintJT2").html();
          var divContents3 = $("#forPrintJT3").html();
      
          printWindow.document.write(divContents);
          printWindow.document.write(divContents3);
          //printWindow.document.write('<p style="page-break-after:always;"></p>');
          printWindow.document.write(divContents2);
          // printWindow.document.write('<img src="../../../../assets/JT_Page2.png" height="150%" width="100%"/>');
          printWindow.document.write('<p style="page-break-after:always;"></p>');
        });
      }      
    });

    printWindow.document.write('</body></html>');
    printWindow.document.close();
    setTimeout(function(){printWindow.focus();printWindow.print();printWindow.close();}, 1000);
    
    this.deleteList = [];
    $('#checkAll').prop('checked',false);
    this.ClonePOList.forEach((data, index) => {
      $('#checkBox'+index).prop('checked',false);
    });
  }

  validate(s){
    return typeof(s) === 'string'? true:false;
  }

  //Pagination
  lengthPL = 0;
  pageSizePL = 10;
  pageSizeOptionsPL: number[] = [10, 25, 50];
  pageIndexPL = 0;
  offsetPL = this.pageSizePL * this.pageIndexPL;
  searchPL: string;
  sorteduPL = {
    active: '',
    direction: ''
  };

  ClonePackingPOList: PackingList_PO_List[] = [];
  ClonePackingList: PackingList[] = [];

  @ViewChild('topPaginatorPL', { read: MatPaginator, static: false }) topPaginatorPL: MatPaginator;
  @ViewChild('bottomPaginatorPL', { read: MatPaginator, static: false }) bottomPaginatorPL: MatPaginator;

  packingFormat: any = /^PKG\d{3}-\d{6}/;
  tempPackingNo: string = "testing";
  packing_controller = new PackingListDB_controller(this.db,this.firestore);
  packingPartList: PackingList_PO_Part_List[] = [];
  packingPOList: PackingList_PO_List[] = [];
  packingList: PackingList[] = [];

  addPacking() {
    this.generatePackingNumber();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = this.tempPackingNo;
    this.dialog.open(AddPackingComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    });
  }
  generatePackingNumber() {
    if (this.packingList.length > 0) {
      const clone = this.packingList.filter(p => p.PackingList_No.includes('PKG'));
      let result = null
      if (clone.length > 0) {
        result = clone[clone.length - 1].PackingList_No.match(this.packingFormat);
      }
      let mid3entries = "000";
      let last6entries = "000000";
      if (result) {
        let last6digits = parseInt(result[0].substring(7, 13));
        if (last6digits < 999999) {
          last6digits = last6digits + 1;
          if (last6digits < 10) {
            last6entries = "00000" + last6digits.toString();
          }
          else if (last6digits < 100) {
            last6entries = "0000" + last6digits.toString();
          }
          else if (last6digits < 1000) {
            last6entries = "000" + last6digits.toString();
          }
          else if (last6digits < 10000) {
            last6entries = "00" + last6digits.toString();
          }
          else if (last6digits < 100000) {
            last6entries = "0" + last6digits.toString();
          }
          else if (last6digits < 1000000) {
            last6entries = last6digits.toString();
          }

          let mid3digits = parseInt(result[0].substring(3, 6));
          if (last6digits == 999999) {
            if (mid3digits < 999) {
              mid3digits = mid3digits + 1;
              if (mid3digits < 10) {
                mid3entries = "00" + mid3digits.toString();
              }
              else if (mid3digits < 100) {
                mid3entries = "0" + mid3digits.toString();
              }
              else if (mid3digits < 1000) {
                mid3entries = mid3digits.toString();
              }
            }
          }
        }
        this.tempPackingNo = "PKG" + mid3entries + "-" + last6entries;
      }
      else {
        this.tempPackingNo = "PKG000-000000";
      }
    }
    else {
      this.tempPackingNo = "PKG000-000000";
    }
  }

  DynamicSearchPL(searchValue: string): void {
    this.searchPL = searchValue;

    this.ClonePackingList = this.packingList.filter(u => {
      const flag = String(u.PackingList_No).toLowerCase().includes(this.searchPL.toLowerCase())
      for (const po of u.PackingList_PO_List) {
        if (po.PO_No.toLowerCase().includes(this.searchPL.toLowerCase()))
          return true;
      }
      return flag
    });


    this.lengthPL = this.ClonePackingList.length;
    this.sortPackingList();
    this.limitPackingList();
    this.topPaginatorPL.firstPage()
    this.bottomPaginatorPL.firstPage()
  }

  DS1() {
    this.ClonePackingList = this.packingList.filter(u => {
      const flag = String(u.PackingList_No).toLowerCase().includes(this.searchPL.toLowerCase())
      for (const po of u.PackingList_PO_List) {
        if (po.PO_No.toLowerCase().includes(this.searchPL.toLowerCase()))
          return true;
      }
      return flag
    });

    this.lengthPL = this.ClonePackingList.length;
  }

  paginator1(pageEvent: PageEvent) {
    this.pageSizePL = pageEvent.pageSize;
    this.pageIndexPL = pageEvent.pageIndex;
    this.offsetPL = this.pageSizePL * this.pageIndexPL;
    if (this.topPaginatorPL.pageIndex < this.pageIndexPL) {
      this.topPaginatorPL.nextPage();
    } else if (this.topPaginatorPL.pageIndex > this.pageIndexPL) {
      this.topPaginatorPL.previousPage();
    }
    if (this.bottomPaginatorPL.pageIndex < this.pageIndexPL) {
      this.bottomPaginatorPL.nextPage();
    } else if (this.bottomPaginatorPL.pageIndex > this.pageIndexPL) {
      this.bottomPaginatorPL.previousPage();
    }
    if (this.searchPL) {
      this.DS1();
    } else {
      this.ClonePackingList = this.packingList.slice();
    }
    this.sortPackingList();
    this.limitPackingList();
  }

  limitPackingList() {
    this.ClonePackingList = this.ClonePackingList.slice(this.offsetPL, (this.offsetPL + this.pageSizePL));
  }

  sortPackingList() {
    if (!this.sorteduPL.active || this.sorteduPL.direction === '') {
      return;
    }
    this.ClonePackingList = this.ClonePackingList.sort((a, b) => {
      const isAsc = this.sorteduPL.direction === 'asc';
      switch (this.sorteduPL.active) {
        case 'packingNo': return this.compare(a.PackingList_No, b.PackingList_No, isAsc);
        case 'shipDate': return this.compareDate(a.Shipping_Date, b.Shipping_Date, isAsc);
        case 'customername': return this.compare(a.customerID, b.customerID, isAsc);
        case 'destination': return this.compare(a.Destination, b.Destination, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'pkgStatus': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;
      }
    });
  }

  sortPackingData(sort: Sort) {
    this.sorteduPL = sort;
    this.ClonePackingList = this.packingList.slice();
    if (this.searchPL) {
      this.DS1();
    }
    if (!sort.active || sort.direction === '' && !this.searchPL) {
      this.ClonePackingList = this.packingList.slice();
      this.limitPackingList();
      return;
    }

    this.ClonePackingList = this.ClonePackingList.sort((a, b) => {
      const isAsc = this.sorteduPL.direction === 'asc';
      switch (this.sorteduPL.active) {
        case 'packingNo': return this.compare(a.PackingList_No, b.PackingList_No, isAsc);
        case 'shipDate': return this.compareDate(a.Shipping_Date, b.Shipping_Date, isAsc);
        case 'customername': return this.compare(a.Customer, b.Customer, isAsc);
        case 'destination': return this.compare(a.Destination, b.Destination, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'pkgStatus': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;
      }
    });
    this.limitPackingList();
  }

  exportToExcelPL() {
    const exportInformation = [];
    this.spinner.show();

    for (const p of this.packingList) {
      const info ={
        "PackingList No":p.PackingList_No || "-",
        "Customer":p.Customer || "-",
        "Destination":p.Destination || "-",
        "Shipping Date":p.Shipping_Date || "-",
        "Created By":p.Created_By,
        "Created Date":p.Created_Date,
        "Purchase Order List":p.PackingList_PO_List.map(a=>a.PO_No +' : '+ a.PackingList_PO_Part_List.map(b=>b.Part_No).join(',')).join('   |   '),

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'PO'+new Date().getTime());
    this.spinner.hide();

  }

  GenerateLabel() {
    this.dialog.open(GenerateQRCodeDialogComponent, {
      width: '80%',
      height: '80%'
    });

  }

  GenerateOldLabel() {
    this.dialog.open(GenerateOldLabelComponent, {
      width: '80%',
      height: 'auto'
    });

  }

  deletePacking(packing: PackingList) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Delete this Packing?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if (confirm) {
        this.packing_controller.delete_PackingList(packing);
        this.setup();
      }
    })

  }

  viewPackingDetail(packingNumber) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      seePacking: packingNumber,
    };
    this.dialog.open(UpdatePackingComponent, dialogConfig).afterClosed().subscribe(result => {
      setTimeout(() => {
        this.setup();
      }, 1000);
    });
  }

  getColorPanel(po:PurchaseOrder, index:any){
    if(po.Status == 'Incomplete' && po.PO_Part_List.length > 0){
      let flag = true;

      po.PO_Part_List.forEach(data => {
        if(data.JOStatus != 'Complete'){
          if(data.EndDate.getTime() - new Date().getTime() <= 259200000){
            flag = false;
            $('#SO' + index).attr('style', 'background-color: rgb(226 8 52 / 10%)');
            return 'rgb(226 8 52 / 25%)';
          }
          else if(data.EndDate.getTime() - new Date().getTime() <= 604800000){
            flag = false;
            $('#SO' + index).attr('style', 'background-color: rgb(226 222 8 / 10%)');
            return 'rgb(226 222 8 / 25%)';
          }
        }
        
      });

      if(flag){
        $('#SO' + index).attr('style', 'background-color: rgb(49 226 8 / 10%)');
        return 'rgb(49 226 8 / 25%)';
      }
    }
  }

  UploadCustomer(){
    const dialogRefupdatePart = this.dialog.open(UploadExcelDialogComponent, {
      width: '80%',
      height: '80%',
      autoFocus: false
    });

    dialogRefupdatePart.afterClosed().subscribe(data => {
      if (data){
        this.spinner.show();

        data.forEach(async data2 => {
          var snapshot = await this.db.database.ref('Customer/' + data2['Debtor Code']).once('value');
  
          if (!snapshot.exists()) {
            const sup = new Customer();
            sup.Customer_ID = data2['Debtor Code'] || uuidv4();
            sup.Customer_Name = data2['Company Name'] || "-";
            sup.Person_In_Charge = data2['Attention'] || "-";
            sup.Address = data2['Address 1'] || "";
            sup.Address2 = data2['Address 2'] || "";
            sup.Address3 = data2['Address 3'] || "";
            sup.Address4 = data2['Address 3'] || "";
            sup.Contact = data2['Phone 1'] || "";
            sup.Contact2 = data2['Phone 2'] || "";
            sup.Fax = data2['Fax 1'] || "";
            sup.Email = data2['Email Address'] || "-";
            this.CustomerDB_controller.add_Customer(sup, this.email);
          }
        });
        
        this.spinner.hide();
        this.setup();
      }
    });
  }

  UploadSO(){
    const dialogRefupdatePart = this.dialog.open(UploadExcelDialogComponent, {
      width: '80%',
      height: '80%',
      autoFocus: false
    });

    dialogRefupdatePart.afterClosed().subscribe(async data => {
      /*const d1 = ['STRATUS', 'GREATECH', 'MAXPAX', 'SKY-TAG', 'SIBS', 'SIP', 'VITROX', 'VITRONIC', 'TORAY', 'TT SOLIC', 'NISSEI'];
      const d2 = ['WAFTECH', 'CALTRONIC', 'NSW', 'TELEFLEX', 'YOSOGO', 'S&N', 'BMI', 'BOSTON', 'HEAMONETICS', 'CORTEX', 'LUMILED', 'RTC'];    
      const d3 = ['PENTA', 'SKY-RESOURCES', 'FCK', 'BMI', 'SIN YAN', 'SEA', 'LT BIMETALIC', 'VENTURE', 'REVO', 'ARROW SYSTEM', 'ARMSTRONG', 'POLYPLAS', 'CHI CHENG', 'ENTHALPY', 'ESCATEC', 'FOUNDPAC'];*/

      if (data){
        this.spinner.show();
        this.soList = [];

        await data.forEach(async data2 => {
          if(data2['Doc. No.'] != null && data2['Qty'] != null && data2['Qty'] != ''){
            var snapshot = await this.db.database.ref('Purchase Order/' + data2['Doc. No.']).once('value');
  
            if (!snapshot.exists() && !this.soList.includes(data2['Doc. No.'])) {
              this.soList.push(data2['Doc. No.']);
              const createdDate = data2['Date'];
              createdDate.setDate(createdDate.getDate() + 1);
              var assignto = "";

              /*if(d1.some(e1 => data2['Debtor Name'].toUpperCase().includes(e1)))
              {
                assignto = this.Staff.find(a => a.StaffName.toUpperCase().includes('ATIKAH')).StaffID;
              }
              else if(d2.some(e2 => data2['Debtor Name'].toUpperCase().includes(e2)))
              {
                assignto = this.Staff.find(a => a.StaffName.toUpperCase().includes('AFIQ')).StaffID;
              }
              else if(d3.some(e3 => data2['Debtor Name'].toUpperCase().includes(e3)))
              {
                assignto = this.Staff.find(a => a.StaffName.toUpperCase().includes('DZUL')).StaffID;
              }*/

              const addPOModel = {
                POName : data2['Doc. No.'],
                poNumber: data2['Shipping Info'] || "",
                CusName : data2['Debtor Name'],
                staffName : data2['Agent'] || "",
                //assignTo : assignto || "",
                createdDate : createdDate.toISOString(),
                parts: []
              };

              await data.forEach(data3 => {
                if(data3['Doc. No.'] == data2['Doc. No.'] && data3['Qty'] != null && data3['Qty'] != ''){
                  var partNo = data3['Detail Description'].substring(0, data3['Detail Description'].indexOf(' '));
                  var partName = '-';
                  
                  if(data3['Detail Description'].substring(data3['Detail Description'].indexOf(' ') + 1) != null 
                  && data3['Detail Description'].substring(data3['Detail Description'].indexOf(' ') + 1) != '' ){
                    partName = data3['Detail Description'].substring(data3['Detail Description'].indexOf(' ') + 1);
                  }

                  const endDate = data3['Delivery Date'];
                  endDate.setDate(endDate.getDate() + 1);

                  if(partNo == null || partNo == '' || partNo == '-'){
                    const info = {
                      partNumber: partNo,
                      partName: partName,
                      id: uuidv4(),
                      poquantity: data3['Qty'],
                      uom: data3['UOM'] || " ",
                      startDate: createdDate.toISOString(),
                      endDate: endDate.toISOString()
                    };
  
                    addPOModel.parts.push(info);
                  }
                  else{
                    let part = this.matSelectPart.find(a=>a.Part_No == partNo);

                    if(part){
                      const info = {
                        partNumber: partNo,
                        partName: partName,
                        id: (partNo!=null && partNo!="" && partNo!="-" && part!=null && part.ID!=null && part.ID!="") ? part.ID : uuidv4(),
                        poquantity: data3['Qty'],
                        uom: data3['UOM'] || " ",
                        startDate: createdDate.toISOString(),
                        endDate: endDate.toISOString()
                      };
    
                      addPOModel.parts.push(info);
                    }
                    else{
                      var partId = uuidv4();

                      const info = {
                        partNumber: partNo,
                        partName: partName,
                        id: partId,
                        poquantity: data3['Qty'],
                        uom: data3['UOM'] || " ",
                        startDate: createdDate.toISOString(),
                        endDate: endDate.toISOString()
                      };
    
                      let temp = new Part();
                      temp.Part_No = partNo;
                      temp.ID = partId;
                      this.matSelectPart.push(temp);
                      addPOModel.parts.push(info);
                    }
                  }
                }
              });

              await this.PODB_controller.addPOWithoutSchedule(addPOModel,this.email);
            }
          }
        });

        this.spinner.hide();
        this.setup();
      }
    });
  }

  UploadIncompleteSO(){
    const dialogRefupdatePart = this.dialog.open(UploadExcelDialogComponent, {
      width: '80%',
      height: '80%',
      autoFocus: false
    });

    dialogRefupdatePart.afterClosed().subscribe(async data => {
      if (data){
        this.spinner.show();
        this.soList = [];

        await data.forEach(async data2 => {
          if(data2['Doc. No.'] != null){
            let so = this.IncompletePOList.find(a => a.PO_No == data2['Doc. No.']);

            if (so && !this.soList.includes(data2['Doc. No.'])) {
              this.soList.push(data2['Doc. No.']);
              var count = 0;

              await data.forEach(async data3 => {
                if(data3['Doc. No.'] == data2['Doc. No.'] && data3['Qty'] != null && data3['Item Code'] == ''){
                  var partNo = data3['Detail Description'].substring(0, data3['Detail Description'].indexOf(' '));
                  var partName = '-';
                  
                  if(data3['Detail Description'].substring(data3['Detail Description'].indexOf(' ') + 1) != null 
                  && data3['Detail Description'].substring(data3['Detail Description'].indexOf(' ') + 1) != '' ){
                    partName = data3['Detail Description'].substring(data3['Detail Description'].indexOf(' ') + 1);
                  }

                  
                  if(partNo == null || partNo == '' || partNo == '-'){
                    count++;
                  }
                  else{
                    let part = this.matSelectPart.find(a=>a.Part_No == partNo);

                    if(part && count<so.PO_Part_List.length){
                      let updates1 = {};
                      count++;
                      updates1[data2['Doc. No.'] + '/Part List/' + data2['Doc. No.'] + '-' + count.toString() + '/Part No'] = part.ID;
                      updates1[data2['Doc. No.'] + '/Part List/' + data2['Doc. No.'] + '-' + count.toString() + '/Part Number'] = part.Part_No;
                      updates1[data2['Doc. No.'] + '/Part List/' + data2['Doc. No.'] + '-' + count.toString() + '/Part Name'] = part.Part_Name;
                      await this.db.database.ref('Purchase Order/').update(updates1);
                    }
                  }
                }
              });
            }
          }
        });

        this.spinner.hide();
        this.setup();
      }
    });
  }

  CheckAll(){
    if($('#checkAll').prop('checked') == true){
      this.ClonePOList.forEach((data, index) => {
        $('#checkBox'+index).prop('checked',true);
        if(this.deleteList.includes(data) == false){
          this.deleteList.push(data);
        }
      });
    }else{
      this.deleteList = [];
      this.ClonePOList.forEach((data, index) => {
        $('#checkBox'+index).prop('checked',false);
      });
      
    }
    
  }

  CheckById(po,i){
    if($('#checkBox'+i).prop('checked') == true){
      this.deleteList.push(po);
    }else{
      const index = this.deleteList.indexOf(po, 0);
      if (index > -1) {
        this.deleteList.splice(index, 1);
      }
    }
  }

  DeleteAll(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you want to delete all this SO?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        await this.deleteList.forEach( data => {
           this.PODB_controller.delete_PO(data.PO_No);
        });
        this.deleteList = [];
        $('#checkAll').prop('checked',false);
        this.setup();
      }
    })
  }

  getColor(quantityNeeded){
    if(quantityNeeded == 'Planned Material'){
      return "red";
    }
    else if(quantityNeeded == "Drawing Released"){
      return "violet"
    }
    else if(quantityNeeded == "Processing"){
      return "blue"
    }
    else if(quantityNeeded == "Complete"){
      return "green"
    }
    else if(quantityNeeded == "Outsourcing"){
      return "purple"
    }
  }

  checkRight(module){
    if(this.role == 'Adminstrator' || this.role == 'Director' || this.role == 'Planner'){
      return true;
    }
    else{
      return this.AccessList.includes(module);
    }
  }
}

